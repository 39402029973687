import gql from "graphql-tag";
import fragments from "@/graphql/fragments";
import { camelToSnakeCase } from "../apollo/helpers";

export const deleteModelMutation = (model) => {
  return gql`mutation Delete${model}($id: ID!){
    delete${model}(id: $id)
    {
      id
    }
  }
  `;
};

export const createModelMutation = (model) => {
  return gql`mutation Create${model}($input: Create${model}Input!){
    create${model}(input: $input)
    {
      ...${camelToSnakeCase(model)}
    }
  }
  ${fragments[camelToSnakeCase(model)]}
  `;
};

/**
 *
 * @param {Object} params
 * @param {String} params.model
 * @param {DocumentNode} params.fragment
 * @param {String} params.fragment_name
 * @returns
 */
export const updateMutationBuilder = ({ model, fragment, fragment_name }) => {
  return gql`mutation Update${model}($id: ID!, $input: Update${model}Input!){
    update${model}(id: $id, input: $input)
    {
      id
      ...${fragment_name}
    }
  }
  ${fragment}
  `;
};

/**
 *
 * @param {Object} params
 * @param {String} params.model
 * @param {DocumentNode} params.fragment
 * @param {String} params.fragment_name
 * @returns
 */
export const deleteMutationBuilder = ({ model, fragment, fragment_name }) => {
  return gql`mutation Delete${model}($id: ID!){
    delete${model}(id: $id)
    {
      id
      ...${fragment_name}
    }
  }
  ${fragment}
  `;
};

/**
 *
 * @param {Object} params
 * @param {String} params.model
 * @param {DocumentNode} params.fragment
 * @param {String} params.fragment_name
 * @returns
 */
export const createMutationBuilder = ({ model, fragment, fragment_name, mutation = null }) => {
  mutation = mutation ?? `create${model}`;
  return gql`mutation Create${model}($input: Create${model}Input!){
    ${mutation}(input: $input)
    {
      id
      ...${fragment_name}
    }
  }
  ${fragment}
  `;
};

export const updateModelMutation = (model) => {
  return gql`mutation Update${model}($id: ID!, $input: Update${model}Input!){
    update${model}(id: $id, input: $input)
    {
      ...${camelToSnakeCase(model)}
    }
  }
  ${fragments[camelToSnakeCase(model)]}
  `;
};

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!, $accepted_privacy_policy: Boolean) {
    login(username: $username, password: $password, accepted_privacy_policy: $accepted_privacy_policy) {
      token
      user {
        id
        type
      }
      privacyPolicy {
        id
        name
        link
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!, $password: String!) {
    createUser(input: $input, password: $password) {
      id
      username
      name
      surname
      email
      phone
      type
      residence {
        id
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UpdateUserInput!, $password: String) {
    updateUser(id: $id, input: $input, password: $password) {
      id
      username
      name
      surname
      email
      phone
      type
      residence {
        id
        name
      }
    }
  }
`;

export const CREATE_STUDENT = gql`
  mutation CreateStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      ...student
    }
  }
  ${fragments.student}
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      ...student
    }
  }
  ${fragments.student}
`;

export const DELETE_STUDENT = gql`
  mutation DeleteStudent($id: ID!) {
    deleteStudent(id: $id) {
      id
    }
  }
`;

export const CREATE_CAREER = gql`
  mutation CreateCareer($input: CreateCareerInput!) {
    createCareer(input: $input) {
      ...career
    }
  }
  ${fragments.career}
`;

export const UPDATE_CAREER_YEAR = gql`
  mutation UpdateCareerYear($id: ID!, $input: UpdateCareerYearInput!) {
    updateCareerYear(id: $id, input: $input) {
      id
      classYear {
        id
        name
      }
      studentYear {
        id
        year {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_CAREER_YEAR = gql`
  mutation CreateCareerYear($input: CreateCareerYearInput!) {
    createCareerYear(input: $input) {
      ...career_year
      exams {
        ...exam
      }
    }
  }
  ${fragments.career_year}
  ${fragments.exam}
`;

export const CREATE_MEAL = gql`
  mutation CreateMeal($input: CreateMealInput!) {
    createMeal(input: $input) {
      ...meal
    }
  }
  ${fragments.meal}
`;

export const REMOVE_SIGN = gql`
  mutation RemoveSign($input: RemoveSignInput!) {
    removeSign(input: $input) {
      id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
