import _get from "lodash/get";
import _set from "lodash/set";
import _orderBy from "lodash/orderBy";

import { createModelMutation, deleteModelMutation, updateModelMutation } from "../graphql/mutations";

//
//---UTILS-------------
//

const removeElementFromChildArray = ({ data, path, id }) => {
  _set(
    data,
    path,
    _get(data, path).filter((element) => element.id !== id)
  );

  return data;
};

const mapToLowerCaseIfString = (value) => {
  if (typeof value === "string") return value.toLowerCase();
  else return value;
};

const addElementToChildArray = ({ data, path, newElement, unshift = false, orderBy = {} }) => {
  //Remove duplicates first
  _set(
    data,
    path,
    _get(data, path).filter((element) => element.id !== newElement.id)
  );

  if (unshift) _set(data, path, [newElement, ..._get(data, path)]);
  else _set(data, path, [..._get(data, path), newElement]);

  if (orderBy)
    _set(
      data,
      path,
      _orderBy(
        _get(data, path),
        Object.keys(orderBy).map((key) => (row) => mapToLowerCaseIfString(row[key])),
        Object.values(orderBy)
      )
    );

  return data;
};

//
//---CACHE MODIFIERS-------------
//

/**
 * Update apollo cache removing a element from a query result
 *
 * @param {Object} store
 * @param {Object} params
 * @param {Object} params.query
 * @param {String} params.queryField
 * @param {Number} params.id
 */
export const removeFromCacheArray = (store, { query, queryField, id }) => {
  try {
    store.writeQuery({
      ...query,
      data: removeElementFromChildArray({ data: store.readQuery(query), path: queryField, id }),
    });
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error);
  }
};

/**
 * Update apollo cache adding a element to a query result
 *
 * @param {Object} store
 * @param {Object} params
 * @param {Object} params.query
 * @param {String} params.queryField
 * @param {Object} params.newElement
 * @param {Boolean} params.unshift
 * @param {String} params.orderBy
 */
export const addToCacheArray = (store, { query, queryField, newElement, unshift = false, orderBy = {} }) => {
  try {
    store.writeQuery({
      ...query,
      data: addElementToChildArray({ data: store.readQuery(query), path: queryField, newElement, unshift, orderBy }),
    });
  } catch (error) {
    if (process.env.NODE_ENV === "development") console.error(error);
  }
};

/**
 * Update apollo cache to adding a element to cached fragment
 *
 * @param {Object} store
 * @param {Object} params
 * @param {Object} params.fragment_info
 * @param {String} params.fragment_info.id
 * @param {Object} params.fragment_info.fragment
 * @param {String} params.fragment_info.fragmentName
 * @param {String} params.array_path
 * @param {Object} params.newElement
 * @param {Boolean} params.unshift
 * @param {String} params.orderBy
 */
export const addToCacheFragmentArray = (
  store,
  { fragment_info, array_path, newElement, unshift = false, orderBy = {} }
) => {
  store.writeFragment({
    ...fragment_info,
    data: addElementToChildArray({
      data: store.readFragment(fragment_info),
      path: array_path,
      newElement,
      unshift,
      orderBy,
    }),
  });
};

/**
 * Update apollo cache to removing a element from cached fragment
 *
 * @param {Object} store
 * @param {Object} params
 * @param {Object} params.fragment_info
 * @param {String} params.fragment_info.id
 * @param {Object} params.fragment_info.fragment
 * @param {String} params.fragment_info.fragmentName
 * @param {String} params.array_path
 * @param {Number} params.id
 */
export const removeFromCacheFragmentArray = (store, { fragment_info, array_path, id }) => {
  store.writeFragment({
    ...fragment_info,
    data: removeElementFromChildArray({
      data: store.readFragment(fragment_info),
      path: array_path,
      id,
    }),
  });
};

//
//---MUTATIONS-------------
//

/**
 * Delete a model and update Apollo Cache
 *
 * @param {Object} $apollo
 * @param {Object} params Composed by id, model, query, queryField, mutationField
 * @param {Number} params.id Id of the item
 * @param {String} params.model Model of the item
 * @param {GQLQuery} params.query Query to update
 * @param {String} params.queryField Field of the query to update
 * @param {GQLQuery} params.mutation Custom mutation
 * @param {String} params.mutationField Custom mutation name
 * @param {Function} params.update Custom mutation name
 */
export const deleteModel = ($apollo, params) => {
  let { id, model, query, queryField, mutation, mutationField, update } = params;

  if (query && !("query" in query)) query = { query };

  mutation ??= deleteModelMutation(model);
  mutationField ??= `delete${model}`;

  update ??= (store, { data }) => removeFromCacheArray(store, { query, queryField, id: data[mutationField].id });

  return $apollo.mutate({
    mutation,
    variables: { id },
    optimisticResponse: { [mutationField]: { __typename: model, id } },
    update,
  });
};

/**
 * Delete a model and update Apollo Cache
 *
 * @param {Object} $apollo
 * @param {Object} params Composed by id, model, query, queryField, mutationField
 * @param {Object} params.item The item
 * @param {String} params.model Model of the item
 * @param {GQLQuery} params.mutation Custom mutation
 * @param {String} params.mutationField Custom mutation name
 * @param {String} params.additionalVariables Additional variables to the mutation
 * @param {Object} params.optimisticResponseFields Optimistic response fields of the mutation
 */
export const updateModel = ($apollo, params) => {
  let {
    item: { id, ...input },
    model,
    mutation,
    mutationField,
    additionalVariables,
    optimisticResponseFields,
  } = params;

  mutation ??= updateModelMutation(model);
  mutationField ??= `update${model}`;

  additionalVariables ??= {};
  optimisticResponseFields ??= {};

  return $apollo.mutate({
    mutation: mutation,
    variables: { id, input, ...additionalVariables },
    optimisticResponse: {
      [mutationField]: { ...optimisticResponseFields, __typename: model, id },
    },
  });
};

/**
 * Delete a model and update Apollo Cache
 *
 * @param {Object} $apollo
 * @param {Object} params Composed by id, model, query, queryField, mutationField
 * @param {Object} params.input The item
 * @param {String} params.model Model of the item
 * @param {GQLQuery} params.query Query to update
 * @param {String} params.queryField Field of the query to update
 * @param {GQLQuery} params.mutation Custom mutation
 * @param {String} params.mutationField Custom mutation name
 * @param {Boolean} params.unshift Put the new item on the top of the stack
 * @param {Function} params.update Put the new item on the top of the stack
 */
export const createModel = ($apollo, params) => {
  let { input, model, query, queryField, mutation, mutationField, additionalVariables, unshift, update } = params;

  mutation ??= createModelMutation(model);
  mutationField ??= `create${model}`;

  additionalVariables ??= {};

  unshift ??= false;

  update ??= (store, { data }) =>
    addToCacheArray(store, { query, queryField, newElement: data[mutationField], unshift });

  if (query && !("query" in query)) query = { query };

  return $apollo.mutate({
    mutation,
    variables: { input, ...additionalVariables },
    update,
  });
};

//
//---OTHER STUFF-------------
//

export const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));

export const camelToSnakeCase = (str) =>
  str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Extract id from nested models
 *
 * @param {*} item
 * @returns
 */
export const extractIds = (item) => {
  return Object.keys(item).reduce((obj, key) => {
    if (isObject(item[key]) && Object.keys(item[key]).includes("id"))
      obj[`${camelToSnakeCase(key)}_id`] = item[key]?.id;
    else obj[key] = item[key];
    return obj;
  }, {});
};

/**
 * Extract id from nested models
 *
 * @param {*} item
 * @returns {Boolean} response
 */
export const isObject = (value) => {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
};
