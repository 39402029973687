import gql from "graphql-tag";

export const STUDENT_STUDENT_YEARS_FRAGMENT = gql`
  fragment studentStudentYears on Student {
    id
    studentYears {
      id
      year {
        id
        name
      }
    }
  }
`;

export const FILE_FRAGMENT = gql`
  fragment file on File {
    id
    name
    link
  }
`;

export const EXAM_FRAGMENT = gql`
  fragment exam on Exam {
    id
    name
    done
    extra_plan
    cfu
    grade
    date
    careerYear {
      id
      classYear {
        id
        name
      }
    }
    classYear {
      id
      name
    }
  }
`;

export default {
  student: gql`
    fragment student on Student {
      id
      name
      gender
      cf
      birthday
      born_in
      born_in_province
      nationality
      address
      city
      province
      cap
      id_card_number
      
      idCard: file(name: "idCard") {
        id
        name
        link
      }
      privacy: file(name: "privacy") {
        id
        name
        link
      }
      cv: file(name: "cv") {
        id
        name
        link
      }
      user {
        id
        name
      }
    }
  `,

  user: gql`
    fragment user on User {
      id
      username
      name
      surname
      email
      phone
      type
      residence {
        id
        name
        area {
          id
          name
          client {
            id
          }
        }
      }
    }
  `,
  exam: EXAM_FRAGMENT,

  career: gql`
    fragment career on Career {
      id
      name
      structure {
        id
        name
      }
      studyClass {
        id
        name
        code
        studyDuration {
          id
          name
        }
        classArea {
          id
          name
        }
      }
    }
  `,

  career_year: gql`
    fragment career_year on CareerYear {
      id
      classYear {
        id
        name
      }
      studentYear {
        id
        year {
          id
          name
        }
      }
    }
  `,

  meal: gql`
    fragment meal on Meal {
      id
      description
      manually_signed
      verified
      updated_at
      user {
        id
        complete_name
        residence {
          id
          name
        }
      }
    }
  `,
  tag: gql`
    fragment tag on Tag {
      id
      name
      color
      max_hours
    }
  `,

  degree: gql`
    fragment degree on Degree {
      id
      name
      date
      type
      grade
    }
  `,

  tuition_range: gql`
    fragment tuition_range on TuitionRange {
      id
      name
      isee_limit
      isee_reduction
    }
  `,

  residence_year: gql`
    fragment residence_year on ResidenceYear {
      id
      max_tuition
      education_bonus
    }
  `,

  year: gql`
    fragment year on Year {
      id
      max_tuition
    }
  `,

  short_event: gql`
    fragment short_event on Event {
      id
      name
      start_timestamp
      end_timestamp
      argument
      teacher
      organizer
      link
      course {
        id
        name
        tag {
          id
          name
          color
        }
        area {
          id
          name
        }
      }

      room {
        id
        name
      }
    }
  `,

  study_class: gql`
    fragment study_class on StudyClass {
      id
      name
      code
      structure
      studyDuration {
        id
        name
      }
      classArea {
        id
        name
      }
    }
  `,
};
